import { AxiosError, AxiosResponse } from "axios";
import { ApiError } from "./error";
import { ApiResponse } from "utils/interfaces/responses/sucess";

export const handleApiError = (error: AxiosError<ApiError>) => {
  console.log(error);
  if (error.response?.status === 401) {
    console.log("unauthorized");
    window.location.href = "/login";
  } else {
    console.log(error);
  }
  return error.response?.data.message || "An error occurred";
};
