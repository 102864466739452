import { HTMLAttributes } from "react";

interface DrawerRootProps extends HTMLAttributes<HTMLDivElement> {
  drawerId: string;
}

export const DrawerRoot = ({
  className,
  children,
  drawerId,
  ...props
}: DrawerRootProps) => {
  return (
    <div
      className={`drawer drawer-end lg:drawer-open flex-1 ${className || ""}`}
      {...props}
    >
      <input id={drawerId} type="checkbox" className="drawer-toggle" />
      {children}
    </div>
  );
};

export default DrawerRoot;
