import CollapseContent from "./CollapseContent";
import CollapseRoot from "./CollapseRoot";
import CollapseTitle from "./CollapseTitle";

const Collapse = {
  Root: CollapseRoot,
  Content: CollapseContent,
  Title: CollapseTitle,
};

export default Collapse;
