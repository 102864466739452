import { Link } from "react-router-dom";

interface MenuItem extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  icon?: React.ReactNode;
  href: string;
  disabled?: boolean;
}

const MenuButton: React.FC<MenuItem> = ({ label, icon, href, ...rest }) => {
  return (
    <div {...rest}>
      <Link to={href}>
        <ul className="menu rounded-box px-0 text-base-content fill-base-content stroke-none">
          <li>
            <summary className="">
              {icon && icon}
              {label}
            </summary>
          </li>
        </ul>
      </Link>
    </div>
  );
};
export default MenuButton;
