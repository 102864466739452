import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { InvoiceTableContext } from "contexts/InvoiceListProvider";
import InvoicesRow from "./Row/InvoicesRow";
import PageController from "./PageController";
import InvoiceHeader from "./Header/InvoicesHeader";
import { InvoiceConfirmModal } from "components/Modal/invoiceConfirm";

const InvoicesTable: React.FC = ({ }) => {
  const { invoicesTable, goToPage, allSelected } =
    useContext(InvoiceTableContext)!;

  return (
    <div className="h-full flex flex-col">
      <div className="min-h-[510px]">
        {allSelected && invoicesTable.data.length !== 0 && (
          <div role="alert" className="alert alert-info alert-soft w-full mb-2">
            <span>
              Todas os {invoicesTable.data.length} resultados dessa pagina estão
              selecionandas
            </span>
            <button className="btn btn-outline btn-soft btn-sm btn-secondary">
              Selecionar todos os {invoicesTable.total} resultados
            </button>
          </div>
        )}
        <div className="overflow-x-auto overflow-y-visible">
          <table className="table w-full">
            <InvoiceHeader />
            <tbody>
              {invoicesTable.data.map((row, index) => (
                <InvoicesRow key={row.id} row={row} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <PageController {...invoicesTable} changePage={goToPage} />
      <InvoiceConfirmModal onClick={() => null} />
    </div>
  );
};

export default InvoicesTable;
