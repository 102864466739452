import React, { createContext, useState, useEffect } from "react";
import { PaginatedResponse, Plan } from "utils/interfaces";
import PlanController from "Controllers/PlanController";

interface TableContextProps {
  tablePlans: PaginatedResponse<Plan>;
  setTablePlans: (plans: PaginatedResponse<Plan>) => void;
  editablePlan: Plan | undefined;
  setEditablePlan: (plan: Plan | undefined) => void;
  goToPage: (page: string | number) => Promise<void>;
  updateCurrentPage: () => Promise<void>;
  goToLastPage: () => Promise<void>;
}

export const TableContext = createContext<TableContextProps | undefined>(
  undefined
);

export const TableProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [editablePlan, setEditablePlan] = useState<Plan>();
  const [tablePlans, setTablePlans] = useState<PaginatedResponse<Plan>>(
    PlanController.defaultPaginatedResponse
  );

  const goToPage = async (page: number | string) => {
    const plans = await PlanController.getPlanPage(page);
    setTablePlans(plans);
  };

  const goToLastPage = async () => {
    const plans = await PlanController.getPlanPage(tablePlans.last_page);
    setTablePlans(plans);
  };

  const updateCurrentPage = async () => {
    const plans = await PlanController.getPlanPage(tablePlans.current_page);
    setTablePlans(plans);
  };

  useEffect(() => {
    goToPage(1);
  }, []);

  return (
    <TableContext.Provider
      value={{
        tablePlans,
        setTablePlans,
        editablePlan,
        setEditablePlan,
        goToPage,
        updateCurrentPage,
        goToLastPage,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};
