import { HTMLAttributes } from "react";

interface CollapseProps extends HTMLAttributes<HTMLDivElement> {}

const CollapseRoot = ({ className, children, ...props }: CollapseProps) => {
  return (
    <div
      className={`collapse bg-base-100 collapse-arrow border-base-300 border mb-2 ${
        className || ""
      }`}
      {...props}
    >
      <input type="checkbox" />
      {children}
    </div>
  );
};

export default CollapseRoot;
