import TextInput from "components/Forms/Input/TextInput";
import { InvoiceTableContext } from "contexts/ClientInvoicesProvider";
import { useContext } from "react";
import { useForm } from "react-hook-form";

const ID_MODAL = "cancel_invoice_modal";

export const cancelInvoiceModalRef = () =>
  document.getElementById(ID_MODAL) as HTMLDialogElement;

interface Props {}

interface CancelFormProps {
  reason: string;
}

export const CancelInvoiceModal: React.FC<Props> = () => {
  const { cancelInvoice, editableInvoice } = useContext(InvoiceTableContext)!;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CancelFormProps>();

  const onSubmit = (data: { reason: string }) => {
    console.log(data.reason);
    editableInvoice && cancelInvoice(editableInvoice.id, data);
    cancelInvoiceModalRef().close();
    reset();
  };

  return (
    <dialog id={ID_MODAL} className="modal">
      <div className="modal-box">
        <h3 className="font-bold text-lg">Confirmar Cancelamento</h3>
        <form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            className="mb-4"
            label="Razão do cancelameto"
            error={errors.reason}
            {...register("reason", { required: true })}
          />
          <div className="flex justify-end flex-row">
            <form method="dialog">
              <button className="btn mr-2">Cancelar</button>
            </form>
            <button
              onClick={() => cancelInvoiceModalRef().close()}
              className="btn btn-warning"
            >
              Remover
            </button>
          </div>
        </form>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
};
