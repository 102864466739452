import { HTMLAttributes } from "react";

interface DrawerContentProps extends HTMLAttributes<HTMLDivElement> {}

export const DrawerContent = ({
  className,
  children,
  ...props
}: DrawerContentProps) => {
  return (
    <div
      className={`drawer-content flex flex-col ${className || ""}`}
      {...props}
    >
      {children}
    </div>
  );
};

export default DrawerContent;
