import React, { useContext } from "react";
import { Link } from "react-router-dom";
import SubText from "./SideBarItens/SubText";
import MenuButton from "./SideBarItens/MenuButton";
import MenuGroup from "./SideBarItens/MenuGroup";
import HoraizontalLine from "./SideBarItens/HorizontalLine";
import { AuthContext } from "contexts/AuthProvider";
import {
  ClientIcon,
  HomeIcon,
  InvoiceIcon,
  RegisterIcon,
} from "assets/icons/SideBar";

type SidebarProps = {};

const Sidebar: React.FC<SidebarProps> = () => {
  const { logout } = useContext(AuthContext);

  return (
    <aside className=" box-border w-[240px] left-menu flex border-r border-base-300 flex-col h-full">
      <nav
        aria-label="Main"
        className="select-none flex-col flex h-full px-2 pb-4 overflow-y-hidden hover:overflow-y-auto"
      >
        <Link
          to={"/"}
          className="text-center w-full flex h-[64px] items-center justify-center text-2xl font-bold tracking-wider uppercase text-base-content"
        >
          CRM Netinove
        </Link>
        <div className="h-6"></div>
        <SubText label="Main" />
        <MenuButton href="/" label="Home" icon={<HomeIcon />} />
        <HoraizontalLine />
        <MenuButton
          href="/clients/index"
          label="Clientes"
          icon={<ClientIcon />}
        />
        <MenuGroup
          label="Cadastros"
          icon={<RegisterIcon />}
          submenu={[{ label: "Planos", disabled: false, href: "/plans/index" }]}
        />
        <MenuButton href="/invoices" label="Faturas" icon={<InvoiceIcon />} />
        <div className="mt-auto"></div>
        <HoraizontalLine />
        <MenuButton
          href="/"
          onClick={logout}
          label="Logout"
          icon={
            <svg
              className="h-5 w-5"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M13.5 7.5L10.5 10.75M13.5 7.5L10.5 4.5M13.5 7.5L4 7.5M8 13.5H1.5L1.5 1.5L8 1.5" />
            </svg>
          }
        />
      </nav>
    </aside>
  );
};

export default Sidebar;
