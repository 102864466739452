import React, { createContext, useState, useEffect, useContext } from "react";
import ClientPlanController from "Controllers/ClientPlanController";
import ClientController from "Controllers/ClientController";
import PlanController from "Controllers/PlanController";
import { Plan, ClientPlan } from "utils/interfaces";
import { useParams } from "react-router-dom";
import { DiscountFormProps } from "components/Forms/interface/PlanForm";
import {
  DiscountModal,
  discountModalRef,
} from "components/Modal/applyDiscount";

interface ClientPlanContextType {
  plans: Plan[];
  tablePlans: ClientPlan;
  updatePlans: () => Promise<void>;
  openModal: (discont: DiscountFormProps) => void;
  clientPlan: DiscountFormProps | undefined;
}

export const ClientPlanContext = createContext<ClientPlanContextType>(
  {} as ClientPlanContextType
);

export const ClientPlanProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { id: client_id } = useParams();
  const [clientPlan, setClientPlan] = useState<DiscountFormProps>();
  const [plans, setPlans] = useState<Plan[]>([]);
  const [tablePlans, setTablePlans] = useState<ClientPlan>(
    ClientController.defaultClientPlan
  );

  const openModal = async (discont: DiscountFormProps) => {
    setClientPlan(discont);
    discountModalRef().showModal();
  };

  const updatePlans = async () => {
    if (client_id) {
      const plans_list = await ClientPlanController.getPlans(client_id);
      setTablePlans(plans_list);
    }
    setPlans(await PlanController.listPlans());
  };

  useEffect(() => {
    updatePlans();
  }, []);

  return (
    <ClientPlanContext.Provider
      value={{
        plans,
        tablePlans,
        updatePlans,
        openModal,
        clientPlan,
      }}
    >
      {children}
      <DiscountModal />
    </ClientPlanContext.Provider>
  );
};
