import React, { createContext, useState, useEffect } from "react";
import { Client, PaginatedResponse } from "utils/interfaces";
import ClientController from "Controllers/ClientController";

interface ClientListContextProps {
  tableClient: PaginatedResponse<Client>;
  setTableClient: (plans: PaginatedResponse<Client>) => void;
  selectedClient?: Client;
  getClient: (id: number | string) => void;
  getClientsPerPage: (page: number | string) => void;
  removeClient: () => void;
  updatePage: () => void;
  goToLastPage: () => void;
}

export const ClientListContext = createContext<
  ClientListContextProps | undefined
>(undefined);

export const ClientProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [tableClient, setTableClient] = useState<PaginatedResponse<Client>>(
    ClientController.defaultPaginatedResponse
  );

  const [selectedClient, setSelectedClient] = useState<Client>();

  const getClientsPerPage = async (page: number | string) => {
    setTableClient(await ClientController.getClientPage(page));
  };

  const updatePage = async () => {
    setTableClient(
      await ClientController.getClientPage(tableClient.current_page)
    );
  };

  const goToLastPage = async () => {
    setTableClient(await ClientController.getClientPage(tableClient.last_page));
  };

  useEffect(() => {
    getClientsPerPage(1);
  }, []);

  const getClient = async (id: number | string) => {
    setSelectedClient(await ClientController.getClientById(id));
  };

  const removeClient = () => {
    setSelectedClient(undefined);
  };

  return (
    <ClientListContext.Provider
      value={{
        tableClient,
        setTableClient,
        selectedClient,
        getClient,
        getClientsPerPage,
        removeClient,
        updatePage,
        goToLastPage,
      }}
    >
      {children}
    </ClientListContext.Provider>
  );
};
