import { ProductType } from "utils/Enums/ProductType";
import {
  ConsigData,
  ConsigProduct,
  DomainPlan,
  Product,
  ProductFormModel,
} from "utils/interfaces/forms/productForm";

export function transformConsigProdutToProduct(
  consigData: ConsigData
): ConsigProduct {
  return {
    devices_quantity: `${consigData.domain_plan.devices_quantity}`,
    devices_quantity_adm: `${consigData.domain_plan.devices_quantity_adm}`,
    devices_quantity_wholesale: `${consigData.domain_plan.devices_quantity_wholesale}`,
    vehicles_quantity: `${consigData.domain_plan.vehicles_quantity}`,
    status: `${consigData.domain_plan.status == 1}`,
    name: "Consig",
    url: consigData.domain.domain,
    id: `${consigData.domain.id}`,
  };
}

export function transformDomainPlanToProduct(
  consigData: DomainPlan
): ConsigProduct {
  return {
    devices_quantity: `${consigData.devices_quantity}`,
    devices_quantity_adm: `${consigData.devices_quantity_adm}`,
    devices_quantity_wholesale: `${consigData.devices_quantity_wholesale}`,
    vehicles_quantity: `${consigData.vehicles_quantity}`,
    status: `${consigData.status == 1}`,
    name: "Consig",
    url: undefined,
    id: undefined,
  };
}

export function transformProdutToProductFormat(product: ProductFormModel) {
  const consig = {
    devices_quantity: Number(product.consig?.devices_quantity),
    devices_quantity_wholesale: Number(
      product.consig?.devices_quantity_wholesale
    ),
    devices_quantity_adm: Number(product.consig?.devices_quantity_adm),
    vehicles_quantity: Number(product.consig?.vehicles_quantity),
    gps_activation_date: product.consig?.active_gps
      ? product.consig?.gps_activation_date
      : null,
  };

  return {
    ...product,
    client_id: product.client_id ? Number(product.client_id) : undefined,
    type: product.type ? Number(product.type) : undefined,
    status: product.status === "true",
    consig: Number(product.type) === ProductType.Consig ? consig : undefined,
  };
}

export function transformProdutToProductFormModel(
  product: Product
): ProductFormModel {
  const consig = {
    devices_quantity: `${product.consig?.domain_plan?.devices_quantity}`,
    devices_quantity_wholesale: `${product.consig?.domain_plan?.devices_quantity_wholesale}`,
    devices_quantity_adm: `${product.consig?.domain_plan?.devices_quantity_adm}`,
    vehicles_quantity: `${product.consig?.domain_plan?.vehicles_quantity}`,
    gps_activation_date: `${
      product.consig?.domain_plan?.gps_activation_date?.split(" ")[0]
    }`,
    active_gps:
      product.consig?.domain_plan?.gps_activation_date &&
      product.consig?.domain_plan?.gps_activation_date !== null
        ? true
        : false,
  };

  return {
    client_id: `${product.client_id}`,
    domain_id: product.domain_id,
    id: `${product.id}`,
    status: `${product.status}`,
    type: `${product.type}`,
    url: product.url,
    consig: consig ?? undefined,
  };
}
