const ClientPlanHeader = () => {
  return (
    <thead>
      <tr>
        <th>#</th>
        <th>Nome</th>
        <th>Status</th>
        <th>Assinatura</th>
        <th>Plano</th>
        <th>Desconto</th>
        <th>Total</th>
        <th>Acões</th>
      </tr>
    </thead>
  );
};

export default ClientPlanHeader;
