interface CardTitleProps {
  title: string;
  children?: React.ReactNode;
}

const CardTitle: React.FC<CardTitleProps> = ({ title, children }) => {
  return (
    <div className="">
      <h2 className="card-title mb-2">
        <div className="mr-auto">{title}</div>
        {children}
      </h2>
    </div>
  );
};

export default CardTitle;
