import React, { useContext, useEffect, useState } from "react";
import { ClientPlan, Plan } from "utils/interfaces";
import ClienteTabs from "components/Tabs/ClienteTabs";
import { useParams } from "react-router-dom";
import LabelInsideInput from "components/Forms/Input/LabelInsideInput";
import Select from "components/Forms/Input/Select";
import { SubmitHandler, useForm } from "react-hook-form";
import ClientController from "Controllers/ClientController";
import ClientPlanTable from "components/Table/ClientePlanTable";
import PlanController from "Controllers/PlanController";
import LabelInsideDateInput from "components/Forms/Input/DateInput";
import ClientPlanController from "Controllers/ClientPlanController";
import { ClientPlanContext } from "contexts/ClientPlanProvider";

interface FormData {
  totalValue: string;
  discount?: string;
  total?: string;
  plan_id: string;
  signature_date: string;
}

const ClientPlans: React.FC = () => {
  const { id: client_id } = useParams();
  const { plans, tablePlans, updatePlans } = useContext(ClientPlanContext);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    defaultValues: {
      signature_date: new Date().toISOString().split("T")[0],
    },
  });

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    if (client_id) {
      await ClientPlanController.attachPlan(
        data.plan_id,
        client_id,
        data.signature_date
      );
      reset();
      updatePlans();
    }
  };

  useEffect(() => {
    setValue("totalValue", Number(tablePlans.total_value).toFixed(2));
    setValue("discount", Number(tablePlans.total_discount).toFixed(2));
    setValue(
      "total",
      (
        Number(tablePlans.total_value) - Number(tablePlans.total_discount)
      ).toFixed(2)
    );
  }, [tablePlans]);

  return (
    <div className="card shadow-xl w-full bg-base-100">
      <div className="card-body">
        <ClienteTabs id={client_id} />
        <form
          className="flex justify-between"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="join join-vertical lg:join-horizontal">
            <LabelInsideInput
              className="bg-neutral flex-1 max-w-44"
              label="Planos"
              error={errors.totalValue}
              placeholder="0.00"
              disabled
              {...register("totalValue", { required: false })}
            />
            <LabelInsideInput
              className="bg-neutral flex-1 max-w-44"
              label="Desconto"
              error={errors.discount}
              placeholder="0.00"
              disabled
              {...register("discount", { required: false })}
            />
            <LabelInsideInput
              className="bg-neutral flex-1 max-w-44"
              label="Total"
              error={errors.total}
              placeholder="0.00"
              disabled
              {...register("total", { required: false })}
            />
          </div>
          <div className="join join-vertical lg:join-horizontal">
            <LabelInsideDateInput
              className="join-item"
              label="Assinatura"
              {...register("signature_date", { required: false })}
            />
            <Select
              className="join-item bg-neutral text-neutral-content box-border"
              options={plans.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
              defaultOption="Selecione um plano"
              error={errors.plan_id}
              {...register("plan_id", { required: true })}
            />
            <button className="btn join-item">Adicionar</button>
          </div>
        </form>
        <div className="divider"></div>
        <ClientPlanTable rows={tablePlans.plans} updatePlans={updatePlans} />
      </div>
    </div>
  );
};

export default ClientPlans;
