import LabelInsideDateInput from "components/Forms/Input/DateInput";
import LabelInsideInput from "components/Forms/Input/LabelInsideInput";
import TextInput from "components/Forms/Input/TextInput";
import { InvoiceTableContext } from "contexts/ClientInvoicesProvider";
import InvoiceController from "Controllers/InvoiceController";
import { useContext, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { parseMonetaryValueToNumber } from "utils/DTOs/MonetaryValue";
import { normalizeMonetaryValue } from "utils/formMasks";
import { InvoiceFormProps } from "utils/interfaces/forms/InvoiceForm";

export const modalRef = () =>
  document.getElementById("invoiceModal") as HTMLDialogElement;

interface Props {}

export const InvoiceModal: React.FC<Props> = ({}) => {
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<InvoiceFormProps>({});

  const { goToLastPage } = useContext(InvoiceTableContext)!;

  const { id: client_id } = useParams();
  const onSubmit: SubmitHandler<InvoiceFormProps> = async (data) => {
    client_id &&
      (await InvoiceController.store(
        {
          ...data,
          total_value: parseMonetaryValueToNumber(`${data.total_value}`),
        },
        client_id
      ));
    goToLastPage();
    modalRef().close();
    reset();
  };

  return (
    <dialog id="invoiceModal" onClose={() => reset()} className="modal">
      <div className="modal-box max-w-[800px] w-full">
        <h3 className="font-bold text-lg">Adicionar Plano</h3>
        <form className="" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-2 gap-2 items-end auto-rows-max">
            <LabelInsideDateInput
              label="Vencimento"
              {...register("invoice_date", { required: true })}
            />
            <LabelInsideInput
              label="Valor"
              error={errors.total_value}
              {...register("total_value", { required: true })}
              onInput={normalizeMonetaryValue}
            />
          </div>
          <TextInput label="message" className="col-span-2" />
          <div className="flex justify-end flex-row my-2">
            <button
              className="btn mr-2"
              onClick={() => {
                modalRef().close();
                reset({});
              }}
            >
              Cancelar
            </button>
            <button type="submit" className="btn btn-warning">
              Adicionar
            </button>
          </div>
        </form>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button onClick={() => reset({})}>close</button>
      </form>
    </dialog>
  );
};
