import { AxiosError } from "axios";
import { toast } from "react-toastify";
import apiClient from "services/axios/CrmApi";
import { Client, PaginatedResponse } from "utils/interfaces";
import { ApiError } from "utils/interfaces/responses/error";
import {
  Invoice,
  InvoiceFilterForm,
  InvoiceTable,
} from "utils/interfaces/Invoices";
import { handleApiError } from "utils/interfaces/responses/handleError";
import { ClientFormModel } from "utils/DTOs/ClientForm";
import { InvoiceFormProps } from "utils/interfaces/forms/InvoiceForm";

export default class InvoiceController {
  static defaultPaginatedResponse: PaginatedResponse<any> = {
    current_page: 1,
    data: [],
    from: 0,
    last_page: 1,
    per_page: 0,
    to: 0,
    total: 0,
  };

  static async list(
    page: number | string,
    filter?: InvoiceFilterForm
  ): Promise<PaginatedResponse<InvoiceTable>> {
    return toast
      .promise(
        apiClient.get(`/invoices`, {
          params: {
            page,
            ...filter,
          },
        }),
        {
          pending: "Atualizando lista de Faturas",
          error: {
            render({ data }: { data: AxiosError<ApiError> }) {
              return `${data.response?.data.message}`;
            },
          },
        }
      )
      .then(({ data }) => {
        if (data.data) {
          return data.data as PaginatedResponse<Invoice>;
        } else {
          return this.defaultPaginatedResponse;
        }
      })
      .catch((error) => {
        handleApiError(error);
        return this.defaultPaginatedResponse;
      });
  }

  static async getByClientID(
    page: number | string,
    client_id: number | string
  ): Promise<PaginatedResponse<Invoice>> {
    return toast
      .promise(apiClient.get(`/invoices/${client_id}?page=${page}`), {
        pending: "Atualizando lista de Faturas",
        error: {
          render({ data }: { data: AxiosError<ApiError> }) {
            return `${data.response?.data.message}`;
          },
        },
      })
      .then(({ data }) => {
        if (data.data) {
          return data.data as PaginatedResponse<Invoice>;
        } else {
          return this.defaultPaginatedResponse;
        }
      })
      .catch((error) => {
        handleApiError(error);
        return this.defaultPaginatedResponse;
      });
  }

  static async store(invoice: InvoiceFormProps, client_id: string | number) {
    return toast
      .promise(apiClient.post(`/invoices/${client_id}/`, invoice), {
        pending: "Cadastrando Fatura",
        success: "Cadastro Concluido",
        error: {
          render({ data }: { data: AxiosError<ApiError> }) {
            return `${data.response?.data.message}`;
          },
        },
      })
      .then((response) => {
        if (response.data) {
          return response.data as PaginatedResponse<Client>;
        } else {
          return this.defaultPaginatedResponse;
        }
      })
      .catch((error) => {
        handleApiError(error);
        return this.defaultPaginatedResponse;
      });
  }

  static async cancelInvoice(
    invoice_id: string | number,
    data: { reason: string }
  ) {
    return toast
      .promise(apiClient.post(`/invoices/${invoice_id}/cancel`, data), {
        pending: "Cancelando fatura...",
        success: "Fatura cancelada com sucesso",
        error: {
          render({ data }: { data: AxiosError<ApiError> }) {
            return `${data.response?.data.message}`;
          },
        },
      })
      .catch((error) => {
        handleApiError(error);
      });
  }

  static async manualUpdate(invoice_id: string | number) {
    return toast
      .promise(apiClient.get(`/invoices/${invoice_id}/manual-update`), {
        pending: "Solicitando atualização manual...",
        success: "Atualização manual solicitada com sucesso",
        error: {
          render({ data }: { data: AxiosError<ApiError> }) {
            return `${data.response?.data.message}`;
          },
        },
      })
      .catch((error) => {
        handleApiError(error);
      });
  }

  static async downloadInvoice(
    invoice_id: string | number,
    file_name?: string
  ) {
    return toast
      .promise(
        apiClient.get(`/invoices/${invoice_id}/download`, {
          responseType: "blob",
        }),
        {
          pending: "Baixando boleto...",
          success: "Boleto baixado com sucesso",
          error: {
            render({ data }: { data: AxiosError<ApiError> }) {
              return `${data.response?.data.message}`;
            },
          },
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;

        const contentDisposition = response.headers["content-disposition"];
        const fileName = file_name
          ? file_name
          : contentDisposition
          ? contentDisposition.split("filename=")[1].replace(/['"]/g, "")
          : "downloaded-file";

        link.setAttribute("download", fileName); // ou o nome do arquivo que você deseja
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        handleApiError(error);
      });
  }
}
