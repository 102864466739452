import LabelInsideInput from "components/Forms/Input/LabelInsideInput";
import TextInput from "components/Forms/Input/TextInput";
import { DiscountFormProps } from "components/Forms/interface/PlanForm";
import { ClientPlanContext } from "contexts/ClientPlanProvider";
import ClientPlanController from "Controllers/ClientPlanController";
import { useContext, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  formatNumberToMonetaryValue,
  parseMonetaryValueToNumber,
} from "utils/DTOs/MonetaryValue";
import { normalizeMonetaryValue } from "utils/formMasks";

export const discountModalRef = () =>
  document.getElementById("discountModal") as HTMLDialogElement;

interface Props {}

export const DiscountModal: React.FC<Props> = ({}) => {
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<DiscountFormProps>({});

  const { updatePlans, clientPlan } = useContext(ClientPlanContext)!;
  const { id: client_id } = useParams();

  const onSubmit: SubmitHandler<DiscountFormProps> = async (data) => {
    if (data.id && client_id) {
      await ClientPlanController.applyDiscount(client_id, data.id, {
        ...data,
        discount: parseMonetaryValueToNumber(`${data.discount}`),
      });
      updatePlans();
    }
    discountModalRef().close();
    reset();
  };

  useEffect(() => {
    clientPlan &&
      reset({
        ...clientPlan,
        discount: formatNumberToMonetaryValue(clientPlan.discount),
      });
  }, [clientPlan]);

  return (
    <dialog id="discountModal" onClose={() => reset()} className="modal">
      <div className="modal-box max-w-[800px] w-full">
        <h3 className="font-bold text-lg mb-4">Adicionar Desconto</h3>
        <form className="" onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" {...register("id")} />

          <div className="grid grid-cols-2 gap-2 items-end  auto-rows-max">
            <TextInput
              label="Valor do Desconto"
              error={errors.discount}
              {...register("discount", { required: true })}
              onInput={normalizeMonetaryValue}
            />{" "}
            <TextInput
              label="Observação"
              error={errors.observation}
              {...register("observation", { required: false })}
            />
          </div>

          <div className="flex justify-end flex-row my-2">
            <button
              className="btn mr-2"
              onClick={() => {
                discountModalRef().close();
                reset({});
              }}
            >
              Cancelar
            </button>
            <button type="submit" className="btn btn-warning">
              Adicionar
            </button>
          </div>
        </form>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button onClick={() => reset({})}>close</button>
      </form>
    </dialog>
  );
};
