import { AxiosError } from "axios";
import { toast } from "react-toastify";
import apiClient from "services/axios/CrmApi";
import { transformProdutToProductFormat } from "utils/DTOs/Product";
import { Product, ProductFormModel } from "utils/interfaces/forms/productForm";
import { ApiError } from "utils/interfaces/responses/error";
import { handleApiError } from "utils/interfaces/responses/handleError";
import { ApiResponse } from "utils/interfaces/responses/sucess";

export default class ProductController {
  static defaultresponse: Product = {
    id: 0,
    client_id: 0,
    created_at: "",
    updated_at: "",
    type: 0,
  };

  static async getByClientId(client_id: string | number) {
    return toast
      .promise(
        apiClient.get<ApiResponse<Product[]>>(`/products/client/${client_id}`),
        {
          pending: "Buscando produtos...",
          error: {
            render({ data }: { data: AxiosError<ApiError> }) {
              return `${data.response?.data.message}`;
            },
          },
        }
      )
      .then(({ data }) => {
        if (data.data) {
          return data.data;
        } else {
          return [];
        }
      })
      .catch((error) => {
        handleApiError(error);
        return [];
      });
  }

  static async show(id: string | number) {
    return toast
      .promise(apiClient.get<ApiResponse<Product>>(`/products/${id}`), {
        pending: "Buscando produto...",
        success: {
          render({ data }) {
            return `${data.data.message}`;
          },
        },
        error: {
          render({ data }: { data: AxiosError<ApiError> }) {
            return `${data.response?.data.message}`;
          },
        },
      })
      .then(({ data }) => {
        if (data.data) {
          return data.data;
        } else {
          return this.defaultresponse;
        }
      })
      .catch((error) => {
        handleApiError(error);
        return this.defaultresponse;
      });
  }

  static async update(id: string | number, data: ProductFormModel) {
    return toast
      .promise(
        apiClient.put<ApiResponse<Product[]>>(
          `/products/${id}`,
          transformProdutToProductFormat(data)
        ),
        {
          pending: "Atualizando produtos...",
          success: {
            render({ data }) {
              return `${data.data.message}`;
            },
          },
          error: {
            render({ data }: { data: AxiosError<ApiError> }) {
              return `${data.response?.data.message}`;
            },
          },
        }
      )
      .then(({ data }) => {
        if (data.data) {
          return data.data;
        } else {
          return [];
        }
      })
      .catch((error) => {
        handleApiError(error);

        return [];
      });
  }

  static async store(data: ProductFormModel) {
    return toast
      .promise(
        apiClient.post<ApiResponse<Product[]>>(
          `/products`,
          transformProdutToProductFormat(data)
        ),
        {
          pending: "Atualizando produtos...",
          success: {
            render({ data }) {
              return `${data.data.message}`;
            },
          },
          error: {
            render({ data }: { data: AxiosError<ApiError> }) {
              return `${data.response?.data.message}`;
            },
          },
        }
      )
      .then(({ data }) => {
        if (data.data) {
          return data.data;
        } else {
          return [];
        }
      })
      .catch((error) => {
        handleApiError(error);

        return [];
      });
  }

  static async delete(product_id: string | number) {
    return toast
      .promise(
        apiClient.delete<ApiResponse<Product>>(`/products/${product_id}`),
        {
          pending: "Removendo produtos...",
          success: {
            render({ data }) {
              return `${data.data.message}`;
            },
          },
          error: {
            render({ data }: { data: AxiosError<ApiError> }) {
              return `${data.response?.data.message}`;
            },
          },
        }
      )
      .then(({ data }) => {
        if (data.data) {
          return data.data;
        } else {
          return this.defaultresponse;
        }
      })
      .catch((error) => {
        handleApiError(error);
        return this.defaultresponse;
      });
  }

  static async disable(id: string | number) {
    return this.update(id, {
      status: "false",
    });
  }
  static async enable(id: string | number) {
    return this.update(id, {
      status: "true",
    });
  }
}
