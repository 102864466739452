import React, { createContext, useState, useEffect } from "react";
import authController from "Controllers/AuthController";
interface AuthContextType {
  loadingCrendetials: boolean;
  isLoggedIn: boolean;
  userId: number | null;
  userName: string | null;
  login: (data: { email: string; password: string }) => void;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [userId, setUserId] = useState<number | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [loadingCrendetials, setLoadingCrendetials] = useState<boolean>(false);
  const [userName, setUserName] = useState<string | null>(null);

  const login = async (data: { email: string; password: string }) => {
    authController
      .login(data)
      .then((response) => {
        setUserId(response.user.id);
        setUserName(response.user.name);
        setIsLoggedIn(true);
      })
      .catch((error) => console.log(error));
  };

  const getUser = async () => {
    setLoadingCrendetials(true);
    await authController
      .getUser()
      .then((response) => {
        setUserId(response.data.id);
        setUserName(response.data.name);
        setIsLoggedIn(true);
        setLoadingCrendetials(false);
      })
      .catch(() => {
        setIsLoggedIn(false);
        setLoadingCrendetials(false);
      });
    setLoadingCrendetials(false);
  };

  const logout = async () => {
    setIsLoggedIn(false);
    await authController.logout();
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        loadingCrendetials,
        isLoggedIn,
        userId,
        userName,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
