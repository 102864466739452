import React, { ReactNode } from "react";
import { DetailedHTMLProps, HTMLAttributes } from "react";
interface CardContentProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const CardBody = ({ children, className, ...props }: CardContentProps) => {
  return (
    <div className={`card-body text-base-content ${className}`} {...props}>
      {children}
    </div>
  );
};

const CardShadow = ({ children, className, ...props }: CardContentProps) => {
  return (
    <div
      className={`card shadow-xl w-full bg-base-100 flex-1 ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};

const CardContent = {
  Body: CardBody,
  Shadow: CardShadow,
};

export default CardContent;
