import Dropdown from "components/Dropdown/dropdown";
import React from "react";
import { Link } from "react-router-dom";
import { formatDocument } from "utils/DTOs/Documents";
import { Address, Client } from "utils/interfaces";
import { IdForm } from "../ClientTable";
interface RowData {
  row: Client;
  onClick: (index?: number) => void;
  active: boolean;
  editRow: () => void;
}

const ClientRows: React.FC<RowData> = ({ row, active, onClick, editRow }) => {
  const convertAddressToString = (address: Address): string => {
    return `${address.number || ""}, ${address.street || ""}, ${
      address.district || ""
    }`;
  };

  const activeRow = () => (!active ? onClick(row.id) : onClick());

  return (
    <React.Fragment key={row.id}>
      <tr className="hover cursor-pointer">
        <th onClick={activeRow}>{row.id}</th>
        <td onClick={activeRow} className="whitespace-nowrap">
          {row.name}
        </td>
        <td onClick={activeRow}>{row.fantasy_name}</td>
        <td onClick={activeRow}>
          {row.document && formatDocument(row.document)}
        </td>
        <td onClick={activeRow}>
          {row.address && convertAddressToString(row.address)}
        </td>
        <td onClick={activeRow}></td>

        <td className="py-0">
          <Dropdown title="Ações">
            <ul>
              <li>
                <Link to={`/clients/${row.id}`}>Visualizar</Link>
              </li>
              <label htmlFor={IdForm} className="">
                <li onClick={editRow}>
                  <a>Editar</a>
                </li>
              </label>
            </ul>
          </Dropdown>
        </td>
      </tr>
      <tr
        className={`transition-all duration-300 ease-in-out transform border-0`}
      >
        <td colSpan={3} className={`overflow-y-hidden p-0`}>
          <div
            className={`transition-all duration-300 ease-in-out transform rounded ${
              active ? "p-4 h-30" : "h-0"
            }`}
          >
            <p>Detalhes:</p>
            <ul className="list-disc pl-6">
              {row.contacts.map((detail, index) => (
                <li key={index}>{detail.contact}</li>
              ))}
            </ul>
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default ClientRows;
