import { AxiosError } from "axios";
import { toast } from "react-toastify";
import apiClient from "services/axios/CrmApi";
import { ApiError } from "utils/interfaces/responses/error";
import { handleApiError } from "utils/interfaces/responses/handleError";

export default class authController {
  static async login(data: { email: string; password: string }) {
    await apiClient.get("/sanctum/csrf-cookie");
    return toast
      .promise(
        apiClient.post("/login", {
          ...data,
          remember: false,
          spa: true,
        }),
        {
          pending: "Realizando Login...",
          error: {
            render({ data }: { data: AxiosError<ApiError> }) {
              const message = data.response?.data.message || data.message;
              return `${message}`;
            },
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => handleApiError(error));
  }

  static async getUser() {
    await apiClient.get("/sanctum/csrf-cookie");
    return apiClient.get("/user");
  }

  static async logout() {
    return apiClient.post("/logout");
  }
}
