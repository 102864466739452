import React from "react";
import { PaginatedResponse } from "utils/interfaces";

const PageController: React.FC<
  PaginatedResponse<any> & { changePage: (index: string | number) => void }
> = ({ from, to, total, current_page, last_page, changePage }) => {
  const generatePagination = (current_page: number, last_page: number) => {
    const pages = [];

    if (current_page <= 3) {
      for (let i = 1; i <= Math.min(4, last_page); i++) {
        pages.push({ page: i, active: i === current_page });
      }

      if (last_page > 4) {
        pages.push({ page: "...", active: false });
        pages.push({ page: last_page, active: false });
      }
    } else if (current_page > last_page - 4) {
      pages.push({ page: 1, active: false });
      pages.push({ page: "...", active: false });

      for (let i = last_page - 3; i <= last_page; i++) {
        pages.push({ page: i, active: i === current_page });
      }
    } else {
      pages.push({ page: 1, active: false });
      pages.push({ page: "...", active: false });
      // Adiciona página anterior, atual e próxima
      pages.push({ page: current_page - 1, active: false });
      pages.push({ page: current_page, active: false });
      pages.push({ page: current_page + 1, active: false });
      pages.push({ page: "...", active: false });
      pages.push({ page: last_page, active: false });
    }

    return pages;
  };

  const pages = generatePagination(current_page, last_page);

  console.log(pages, current_page);

  return (
    <div className="mt-auto">
      <div className="flex items-center justify-between p-6">
        <div className="text-base-content/80 flex gap-2 text-sm">
          {/* <span className="hidden sm:inline">Per page</span>
          <select className="select select-xs w-18" aria-label="Per page">
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select> */}
        </div>
        <span className="text-base-content/80 hidden text-sm lg:inline">
          Mostrando{" "}
          <span className="text-base-content font-medium">
            {from} a {to}
          </span>{" "}
          de {total}
        </span>
        <div className="inline-flex items-center gap-1">
          <button
            className="btn btn-circle sm:btn-sm btn-xs btn-ghost"
            aria-label="Prev"
            onClick={() => changePage(current_page - 1)}
            disabled={current_page === 1}
          >
            <span className="iconify lucide--chevron-left">{"<"}</span>
          </button>
          {pages.map(({ page, active }) => (
            <button
              key={page}
              className={
                "btn btn-circle sm:btn-sm btn-xs " +
                (page === "..." ? "btn-disabled" : "") +
                (active ? "btn-primary" : "btn-ghost")
              }
              onClick={() => changePage(page)}
            >
              {page}
            </button>
          ))}
          <button
            className="btn btn-circle sm:btn-sm btn-xs btn-ghost"
            aria-label="Next"
            disabled={current_page === last_page}
            onClick={() => changePage(current_page + 1)}
          >
            <span className="iconify lucide--chevron-right">{">"}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PageController;
