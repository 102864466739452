import React from "react";
import { Card } from "components/Layout/CardContent";
import { InvoiceTableProvider } from "contexts/InvoiceListProvider";
import InvoicesTable from "components/Table/InvoicesTable";
import Filter from "components/Filters/InvoiceForm";
import { useForm } from "react-hook-form";
import InvoiceDrawer, {
  InvoiceFilterDrawerRef,
} from "components/Filters/invoiceDrawer";
import { LockIcon } from "assets/icons/SideBar";

interface FilterForm {
  sort_by: string;
  sort_by_label?: string;
}

const ClientList: React.FC = () => {
  const { handleSubmit, register, watch, setValue } = useForm<FilterForm>();

  const onSubmit = (data: any) => {
    console.log(data);
  };

  const { sort_by_label } = watch();
  const changeValue = (value: string, label: string) => {
    setValue("sort_by", value);
    setValue("sort_by_label", label);
  };
  return (
    <Card.Root>
      <Card.Title title="Faturas" />
      <InvoiceTableProvider>
        <InvoiceDrawer>
          <Card.Content.Shadow className="flex-none mb-2">
            <Card.Content.Body className="flex flex-row">
              <button className="btn btn-sm btn-wide">
                <LockIcon className="size-[1.2em]" />
                Bloquear Selecionados
              </button>
              <button
                className="ml-auto btn btn-wide btn-sm"
                onClick={() => (InvoiceFilterDrawerRef().checked = true)}
              >
                Filtro
              </button>
            </Card.Content.Body>
          </Card.Content.Shadow>
          <Card.Content.Shadow>
            <Card.Content.Body>
              <InvoicesTable />
            </Card.Content.Body>
          </Card.Content.Shadow>
        </InvoiceDrawer>
      </InvoiceTableProvider>
    </Card.Root>
  );
};

export default ClientList;
