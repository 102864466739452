import React from "react";

interface IconProps extends React.SVGProps<SVGSVGElement> {}

export function HomeIcon(props: IconProps) {
  return (
    <svg
      className="h-5 w-5"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 5.5L0.209381 5.09313C0.0779828 5.18699 0 5.33852 0 5.5H0.5ZM7.5 0.5L7.79062 0.0931333C7.61677 -0.0310444 7.38323 -0.0310444 7.20938 0.0931333L7.5 0.5ZM14.5 5.5H15C15 5.33852 14.922 5.18699 14.7906 5.09313L14.5 5.5ZM2.5 7.5V7H2V7.5H2.5ZM12.5 7.5H13V7H12.5V7.5ZM1 15V5.5H0V15H1ZM0.790619 5.90687L7.79062 0.906867L7.20938 0.0931333L0.209381 5.09313L0.790619 5.90687ZM7.20938 0.906867L14.2094 5.90687L14.7906 5.09313L7.79062 0.0931333L7.20938 0.906867ZM14 5.5V15H15V5.5H14ZM3 15V7.5H2V15H3ZM2.5 8H12.5V7H2.5V8ZM12 7.5V15H13V7.5H12ZM2.5 11H12.5V10H2.5V11ZM6 13H9V12H6V13Z"
        fill="black"
      />
    </svg>
  );
}

export function InvoiceIcon(props: IconProps) {
  return (
    <svg
      className="h-5 w-5"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 6L13.5 4.5M7.5 0V4.5M7.5 4.5C9.15685 4.5 10.5 5.84315 10.5 7.5C10.5 9.15685 9.15685 10.5 7.5 10.5C5.84315 10.5 4.5 9.15685 4.5 7.5C4.5 5.84315 5.84315 4.5 7.5 4.5ZM9.5 9.5L12.5 12.5M7.5 14.5C3.63401 14.5 0.5 11.366 0.5 7.5C0.5 3.63401 3.63401 0.5 7.5 0.5C11.366 0.5 14.5 3.63401 14.5 7.5C14.5 11.366 11.366 14.5 7.5 14.5Z"
        stroke="black"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ClientIcon(props: IconProps) {
  return (
    <svg
      className="h-5 w-5"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 12.5V13H3V12.5H2ZM7 12.5V13H8V12.5H7ZM3 12.5V11.9999H2V12.5H3ZM7 11.9997V12.5H8V11.9997H7ZM5 10C6.10466 10 7 10.8952 7 11.9997H8C8 10.3427 6.65677 9 5 9V10ZM3 11.9999C3 10.8953 3.8954 10 5 10V9C3.34318 9 2 10.343 2 11.9999H3ZM5 4C3.89543 4 3 4.89543 3 6H4C4 5.44772 4.44772 5 5 5V4ZM7 6C7 4.89543 6.10457 4 5 4V5C5.55228 5 6 5.44772 6 6H7ZM5 8C6.10457 8 7 7.10457 7 6H6C6 6.55228 5.55228 7 5 7V8ZM5 7C4.44772 7 4 6.55228 4 6H3C3 7.10457 3.89543 8 5 8V7ZM1.5 3H13.5V2H1.5V3ZM14 3.5V11.5H15V3.5H14ZM13.5 12H1.5V13H13.5V12ZM1 11.5V3.5H0V11.5H1ZM1.5 12C1.22386 12 1 11.7761 1 11.5H0C0 12.3284 0.671574 13 1.5 13V12ZM14 11.5C14 11.7761 13.7761 12 13.5 12V13C14.3284 13 15 12.3284 15 11.5H14ZM13.5 3C13.7761 3 14 3.22386 14 3.5H15C15 2.67157 14.3284 2 13.5 2V3ZM1.5 2C0.671573 2 0 2.67157 0 3.5H1C1 3.22386 1.22386 3 1.5 3V2ZM9 6H12V5H9V6ZM9 9H12V8H9V9ZM0 15H15V14H0V15ZM3 0V2.5H4V0H3ZM11 0V2.5H12V0H11Z"
        fill="black"
      />
    </svg>
  );
}

export function RegisterIcon(props: IconProps) {
  return (
    <svg
      className="h-5 w-5"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4 0.5H1.5C0.947715 0.5 0.5 0.947715 0.5 1.5V4M6 0.5H9M11 0.5H13.5C14.0523 0.5 14.5 0.947715 14.5 1.5V4M0.5 6V9M14.5 6V9M0.5 11V13.5C0.5 14.0523 0.947715 14.5 1.5 14.5H4M14.5 11V13.5C14.5 14.0523 14.0523 14.5 13.5 14.5H11M7.5 4V11M4 7.5H11M6 14.5H9" />
    </svg>
  );
}

export function UserIcon(props: IconProps) {
  return (
    <svg
      className="h-5 w-5"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4 0.5H1.5C0.947715 0.5 0.5 0.947715 0.5 1.5V4M6 0.5H9M11 0.5H13.5C14.0523 0.5 14.5 0.947715 14.5 1.5V4M0.5 6V9M14.5 6V9M0.5 11V13.5C0.5 14.0523 0.947715 14.5 1.5 14.5H4M14.5 11V13.5C14.5 14.0523 14.0523 14.5 13.5 14.5H11M7.5 4V11M4 7.5H11M6 14.5H9" />
    </svg>
  );
}
export function LockIcon(props: IconProps) {
  return (
    <svg
      className="h-5 w-5"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.5 6.5V3.5C4.5 1.84315 5.84315 0.5 7.5 0.5C9.15685 0.5 10.5 1.84315 10.5 3.5V6.5M2.5 6.5H12.5C13.0523 6.5 13.5 6.94772 13.5 7.5V13.5C13.5 14.0523 13.0523 14.5 12.5 14.5H2.5C1.94772 14.5 1.5 14.0523 1.5 13.5V7.5C1.5 6.94772 1.94772 6.5 2.5 6.5Z"
        stroke="black"
      />
    </svg>
  );
}
