import Collapse from "components/Collapse";
import Drawer from "components/Drawer";
import React, { useContext } from "react";
import Select from "../Forms/Input/Select";
import TextInput from "components/Forms/Input/TextInput";
import { useForm } from "react-hook-form";
import { InvoiceTableContext } from "contexts/InvoiceListProvider";
import { InvoiceFilterForm } from "utils/interfaces/Invoices";

interface InvoiceDrawerProps {
  children?: React.ReactNode;
}

const DRAWER_ID = "my-drawer";

export const InvoiceFilterDrawerRef = () =>
  document.getElementById(DRAWER_ID) as HTMLInputElement;

const InvoiceDrawer: React.FC<InvoiceDrawerProps> = ({ children }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<InvoiceFilterForm>();

  const { filter } = useContext(InvoiceTableContext)!;
  const onSubmit = (data: InvoiceFilterForm) => {
    filter(data);
  };

  return (
    <Drawer.Root drawerId={DRAWER_ID}>
      <Drawer.Content>{children}</Drawer.Content>
      <Drawer.SideContent drawerId={DRAWER_ID}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="menu bg-base-200 text-base-content min-h-full w-80 p-4"
        >
          <div className="text-lg font-semibold mb-2">Filtros</div>
          <Collapse.Root>
            <Collapse.Title>Ordenar</Collapse.Title>
            <Collapse.Content>
              <Select
                defaultOption="Ordenar Por"
                className="mb-2"
                options={[{ value: "name", label: "Nome" }]}
                {...register("sort_by")}
                error={errors.sort_by}
              />
              <Select
                defaultOption="Ordem"
                options={[
                  { value: "asc", label: "Crescente" },
                  { value: "desc", label: "Decrecente" },
                ]}
                {...register("order")}
                error={errors.order}
              />
            </Collapse.Content>
          </Collapse.Root>

          <Collapse.Root>
            <Collapse.Title>Data</Collapse.Title>
            <Collapse.Content>
              <Select
                defaultOption="Coluna"
                options={[
                  { value: "created_at", label: "Data de Emissão" },
                  { value: "date_due", label: "Data de Vencimento" },
                ]}
                {...register("date_column")}
                error={errors.date_column}
              />
              <TextInput
                label="Data inicial"
                type="date"
                {...register("date_start")}
                error={errors.date_start}
              />
              <TextInput
                label="Data final"
                type="date"
                {...register("date_end")}
                error={errors.date_end}
              />
            </Collapse.Content>
          </Collapse.Root>

          <Collapse.Root>
            <Collapse.Title>Status</Collapse.Title>
            <Collapse.Content>
              <Select
                defaultOption="Status"
                options={[
                  { value: "A_RECEBER", label: "A RECEBER" },
                  { value: "CANCELADO", label: "CANCELADO" },
                  { value: "RECEBIDO", label: "RECEBIDO" },
                ]}
                {...register("state")}
                error={errors.state}
              />
            </Collapse.Content>
          </Collapse.Root>

          <button type="reset" onClick={() => reset()} className="btn btn-block btn-sm mt-auto mb-2">
            Reset
          </button>
          <button className="btn btn-block btn-primary">Filtrar</button>
        </form>
      </Drawer.SideContent>
    </Drawer.Root>
  );
};

export default InvoiceDrawer;
