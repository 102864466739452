import Dropdown from "components/Dropdown/dropdown";
import { cancelInvoiceModalRef } from "components/Modal/cancelInvoiceModal";
import { InvoiceTableContext } from "contexts/InvoiceListProvider";
import React, { useContext } from "react";
import { InvoiceTable } from "utils/interfaces/Invoices";
interface RowData {
  row: InvoiceTable;
  onClick?: (id: number | string) => void;
}

const InvoicesRow: React.FC<RowData> = ({ row }) => {
  const {
    manualUpdate,
    setEditableInvoice,
    downloadPdf,
    selectedInvoices,
    toggleSelection,
  } = useContext(InvoiceTableContext)!;
  const copyToClipboard = (text: string | undefined) => {
    if (text) {
      navigator.clipboard.writeText(text).then(
        () => {
          console.log("Texto copiado para a área de transferência");
        },
        (err) => {
          console.error("Erro ao copiar texto: ", err);
        }
      );
    }
  };

  function formatDate(apiDate: string) {
    const date = new Date(apiDate);
    return date.toLocaleString("pt-BR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  }

  const cancelInvoice = async () => {
    cancelInvoiceModalRef().showModal();
    setEditableInvoice(row);
  };

  return (
    <React.Fragment key={row.id}>
      <tr className={"hover cursor-pointer"}>
        <th>
          <input
            type="checkbox"
            checked={selectedInvoices.has(row.id)}
            onChange={() => toggleSelection(row.id)}
            className="checkbox"
          />
        </th>
        <th>{row.id}</th>
        <td className="whitespace-nowrap">{row.our_number}</td>
        <td className="whitespace-nowrap">{row.name}</td>
        <td>{formatDate(row.created_at)}</td>
        <td>{formatDate(row.date_due)}</td>
        <td>{row.state}</td>
        <td>{row.value}</td>
        <td>{row.value_paid}</td>
        <td className="py-0">
          <Dropdown title="Ações">
            <ul>
              <li>
                <div onClick={() => manualUpdate(row.id)}>Atualizar</div>
              </li>
              <li
                onClick={() =>
                  downloadPdf(
                    row.id,
                    `${row.client_id}_${row.bank}_${row.claim_code}.pdf`
                  )
                }
              >
                <div>Acessar PDF</div>
              </li>
              <li onClick={cancelInvoice}>
                <div>Cancelar</div>
              </li>
              <li className={row.typeable_line ? "" : "disabled"}>
                <div onClick={() => copyToClipboard(row.typeable_line)}>
                  Copiar linha digitável
                </div>
              </li>
              <li className={row.pix_code ? "" : "disabled"}>
                <div onClick={() => copyToClipboard(row.pix_code)}>
                  Copiar PIX
                </div>
              </li>
            </ul>
          </Dropdown>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default InvoicesRow;
