import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { PaginatedResponse } from "utils/interfaces";
import {
  Invoice,
  InvoiceFilterForm,
  InvoiceTable,
} from "utils/interfaces/Invoices";
import InvoiceController from "Controllers/InvoiceController";
import { useParams } from "react-router-dom";

interface InvoiceTableContextProps {
  invoicesTable: PaginatedResponse<InvoiceTable>;
  setInvoicesTable: (plans: PaginatedResponse<InvoiceTable>) => void;
  selectedInvoices: Set<number | string>;
  toggleSelection: (invoiceId: number | string) => void;
  toggleAll: () => void;
  allSelected: boolean;
  editableInvoice: InvoiceTable | undefined;
  setEditableInvoice: (plan: InvoiceTable | undefined) => void;
  goToPage: (page: number | string) => Promise<void>;
  updateCurrentPage: () => Promise<void>;
  goToLastPage: () => Promise<void>;
  filter: (filter: InvoiceFilterForm) => Promise<void>;
  cancelInvoice: (
    invoice_id: number | string,
    data: { reason: string }
  ) => Promise<void>;
  manualUpdate: (invoice_id: number | string) => Promise<void>;
  downloadPdf: (
    invoice_id: number | string,
    filename?: string
  ) => Promise<void>;
}

export const InvoiceTableContext = createContext<
  InvoiceTableContextProps | undefined
>(undefined);

export const InvoiceTableProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [editableInvoice, setEditableInvoice] = useState<InvoiceTable>();
  const [invoicesTable, setInvoicesTable] = useState<
    PaginatedResponse<InvoiceTable>
  >(InvoiceController.defaultPaginatedResponse);
  const [selectedInvoices, setSelectedInvoices] = useState(
    new Set<number | string>()
  );

  const toggleSelection = useCallback((invoiceId: number | string) => {
    setSelectedInvoices((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(invoiceId)) {
        newSet.delete(invoiceId);
      } else {
        newSet.add(invoiceId);
      }
      return newSet;
    });
  }, []);

  const toggleAll = useCallback(() => {
    setSelectedInvoices((prev) =>
      prev.size === invoicesTable.data.length
        ? new Set()
        : new Set(invoicesTable.data.map((inv) => inv.id))
    );
  }, [invoicesTable]);

  const allSelected = useMemo(
    () => selectedInvoices.size === invoicesTable.data.length,
    [selectedInvoices, invoicesTable.data]
  );

  const isAnySelected = useMemo(
    () => selectedInvoices.size > 0,
    [selectedInvoices]
  );

  const filter = async (filter: InvoiceFilterForm) => {
    const plans = await InvoiceController.list(1, filter);
    setInvoicesTable(plans);
  };

  const goToPage = async (page: number | string) => {
    const plans = await InvoiceController.list(page);
    setInvoicesTable(plans);
  };

  const goToLastPage = async () => {
    const plans = await InvoiceController.list(invoicesTable.last_page);
    setInvoicesTable(plans);
  };

  const updateCurrentPage = async () => {
    const plans = await InvoiceController.list(invoicesTable.current_page);
    setInvoicesTable(plans);
  };

  const cancelInvoice = async (
    invoice_id: number | string,
    data: { reason: string }
  ) => {
    await InvoiceController.cancelInvoice(invoice_id, data);
    updateCurrentPage();
  };

  const manualUpdate = async (invoice_id: number | string) => {
    await InvoiceController.manualUpdate(invoice_id);
    updateCurrentPage();
  };

  const downloadPdf = async (
    invoice_id: number | string,
    filename?: string
  ) => {
    await InvoiceController.downloadInvoice(invoice_id, filename);
  };

  useEffect(() => {
    goToPage(1);
  }, []);

  return (
    <InvoiceTableContext.Provider
      value={{
        editableInvoice,
        invoicesTable,
        goToLastPage,
        filter,
        goToPage,
        updateCurrentPage,
        setEditableInvoice,
        setInvoicesTable,
        cancelInvoice,
        manualUpdate,
        downloadPdf,
        selectedInvoices,
        toggleSelection,
        toggleAll,
        allSelected,
      }}
    >
      {children}
    </InvoiceTableContext.Provider>
  );
};
