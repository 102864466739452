import React, { useContext, useEffect } from "react";
import ClientInvoiceHeader from "./Header/ClientInvoicesHeader";
import ClientInvoicesRow from "./Row/ClientInvoicesRow";
import { InvoiceTableContext } from "contexts/ClientInvoicesProvider";
import { InvoiceModal } from "components/Modal/invoiceModal";
import { CancelInvoiceModal } from "components/Modal/cancelInvoiceModal";

const ClientInvoicesTable: React.FC = ({}) => {
  const { goToPage, invoicesTable } = useContext(InvoiceTableContext)!;

  return (
    <div>
      <div className="min-h-[510px]">
        <table className="table w-full">
          <ClientInvoiceHeader />
          <tbody>
            {invoicesTable.data.map((row, index) => (
              <ClientInvoicesRow row={row} />
            ))}
          </tbody>
        </table>
      </div>
      <CancelInvoiceModal />
      <InvoiceModal />
    </div>
  );
};

export default ClientInvoicesTable;
