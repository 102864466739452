import { HTMLAttributes } from "react";

interface CollapseContentProps extends HTMLAttributes<HTMLDivElement> {}

const CollapseContent = ({
  className,
  children,
  ...props
}: CollapseContentProps) => {
  return (
    <div className={`collapse-content text-sm ${className || ""}`} {...props}>
      {children}
    </div>
  );
};

export default CollapseContent;
