import { AxiosError } from "axios";
import { toast } from "react-toastify";
import apiClient from "services/axios/CrmApi";
import { ClientCache } from "utils/ClientCache";
import {
  ClientFormModel,
  transformFormModelToRequest,
} from "utils/DTOs/ClientForm";
import { formatDocument, formatZipCode } from "utils/DTOs/Documents";
import {
  Client,
  ClientPlan,
  Location,
  PaginatedResponse,
} from "utils/interfaces";
import { ApiError } from "utils/interfaces/responses/error";
import { handleApiError } from "utils/interfaces/responses/handleError";
import { ApiResponse } from "utils/interfaces/responses/sucess";
import ClientPlanController from "./ClientPlanController";

export default class ClientController {
  static defaultPaginatedResponse: PaginatedResponse<Client> = {
    current_page: 1,
    data: [],
    from: 0,
    last_page: 1,
    per_page: 0,
    to: 0,
    total: 0,
  };

  static defaultClientPlan: ClientPlan = ClientPlanController.defaultClientPlan;

  static async getClientPage(
    page: number | string
  ): Promise<PaginatedResponse<Client>> {
    return toast
      .promise(apiClient.get(`/clients/index?page=${page}`), {
        pending: "Atualizando lista de Clientes...",
        error: {
          render({ data }: { data: AxiosError<ApiError> }) {
            return `${data.response?.data.message}`;
          },
        },
      })
      .then(({ data }) => {
        if (data.data) {
          return data.data as PaginatedResponse<Client>;
        } else {
          return this.defaultPaginatedResponse;
        }
      })
      .catch((error) => {
        handleApiError(error);
        return this.defaultPaginatedResponse;
      });
  }

  static async search(query: string): Promise<Client[]> {
    return toast
      .promise(
        apiClient.get<ApiResponse<Client[]>>(`/clients?query=${query}`),
        {
          pending: "Atualizando lista de Clientes...",
          error: {
            render({ data }: { data: AxiosError<ApiError> }) {
              return `${data.response?.data.message}`;
            },
          },
        }
      )
      .then(({ data }) => {
        if (data.data) {
          return data.data;
        } else {
          return [];
        }
      })
      .catch((error) => {
        handleApiError(error);
        return [];
      });
  }
  static async getClientById(id: number | string): Promise<Client | undefined> {
    return toast
      .promise(apiClient.get<ApiResponse<Client>>(`/clients/${id}`), {
        pending: "Obtendo Informações de cliente...",
        error: {
          render({ data }: { data: AxiosError<ApiError> }) {
            return `${data.response?.data.message}`;
          },
        },
      })
      .then(({ data }) => {
        if (data.data) {
          ClientCache.addItemToCache(data.data);
          return {
            ...data.data,
            document: data.data.document && formatDocument(data.data.document),
            address: {
              ...data.data.address,
              zip:
                data.data.address?.zip && formatZipCode(data.data.address?.zip),
            },
          } as Client;
        } else {
          return undefined;
        }
      })
      .catch((error) => {
        handleApiError(error);
        return undefined;
      });
  }

  static async store(client: ClientFormModel) {
    return toast
      .promise(
        apiClient.post<ApiResponse<PaginatedResponse<Client>>>(
          `/clients/store`,
          transformFormModelToRequest(client)
        ),
        {
          pending: "Cadastrando Cliente",
          success: {
            render({ data }) {
              return `${data.data.message}`;
            },
          },
          error: {
            render({ data }: { data: ApiError }) {
              return `${data.message}`;
            },
          },
        }
      )
      .then(({ data }) => {
        if (data.data) {
          return data.data;
        } else {
          return this.defaultPaginatedResponse;
        }
      })
      .catch((error) => {
        handleApiError(error);
        return this.defaultPaginatedResponse;
      });
  }

  static async put(client: ClientFormModel) {
    return toast
      .promise(
        apiClient.put(
          `/clients/${client.id}`,
          transformFormModelToRequest(client)
        ),
        {
          pending: "Atualizando Cliente",
          success: {
            render({ data }) {
              return `${data.data.message}`;
            },
          },
          error: {
            render({ data }: { data: ApiError }) {
              return `${data.message}`;
            },
          },
        }
      )
      .then(({ data }) => {
        if (data.data) {
          return data.data as Client;
        } else {
          return undefined;
        }
      })
      .catch((error) => {
        handleApiError(error);
        return undefined;
      });
  }

  static async getStates(ignore_cache: boolean = false): Promise<Location[]> {
    const cachedStates = sessionStorage.getItem("states");

    if (!ignore_cache && cachedStates) {
      return JSON.parse(cachedStates);
    }

    return toast
      .promise(apiClient.get(`/states`), {
        pending: "Obtendo Lista de Estados",
        error: {
          render({ data }: { data: AxiosError<ApiError> }) {
            return `${data.response?.data.message}`;
          },
        },
      })
      .then(({ data }) => {
        if (data.data) {
          sessionStorage.setItem("states", JSON.stringify(data.data));
          return data.data;
        } else {
          return [];
        }
      })
      .catch((error) => {
        handleApiError(error);
        return [];
      });
  }

  static async getCities(
    id: string,
    ignore_cache: boolean = false
  ): Promise<Location[]> {
    const cachedCities = sessionStorage.getItem(`cities_${id}`);

    if (!ignore_cache && cachedCities) {
      return JSON.parse(cachedCities);
    }

    return toast
      .promise(apiClient.get(`/cities/${id}`), {
        pending: "Atualizando Lista de Cidades",
        error: {
          render({ data }: { data: AxiosError<ApiError> }) {
            return `${data.response?.data.message}`;
          },
        },
      })
      .then(({ data }) => {
        if (data.data) {
          sessionStorage.setItem(`cities_${id}`, JSON.stringify(data.data));
          return data.data;
        } else {
          return [];
        }
      })
      .catch((error) => {
        handleApiError(error);
        return [];
      });
  }
}
