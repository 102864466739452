import { ReactNode } from "react";

interface CardRootProps {
  children: ReactNode;
}

const CardRoot = ({ children }: CardRootProps) => {
  return <div className="h-full w-full flex flex-col">{children}</div>;
};

export default CardRoot;
