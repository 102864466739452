import { HTMLAttributes } from "react";

interface DrawerContentProps extends HTMLAttributes<HTMLDivElement> {
  drawerId: string;
}

export const DrawerSideContent = ({
  className,
  children,
  drawerId,
  ...props
}: DrawerContentProps) => {
  return (
    <div
      className={`drawer-side ml-2 lg:h-auto h-screen z-50 ${className || ""}`}
      {...props}
    >
      <label
        htmlFor={drawerId}
        aria-label="close sidebar"
        className="drawer-overlay"
      ></label>
      {children}
    </div>
  );
};

export default DrawerSideContent;
