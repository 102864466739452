import { InvoiceTableContext } from "contexts/InvoiceListProvider";
import React, { useContext } from "react";

const InvoiceHeader = () => {
  const { toggleAll, allSelected } = useContext(InvoiceTableContext)!;

  return (
    <thead>
      <tr>
        <th>
          <input
            type="checkbox"
            className="checkbox"
            onChange={toggleAll}
            checked={allSelected}
          />
        </th>
        <th>#</th>
        <th>Nosso Nº</th>
        <th>Nome</th>
        <th>Emissão</th>
        <th>Vencimento</th>
        <th>Status</th>
        <th>Valor R$</th>
        <th>Pago R$</th>
        <th>Ações</th>
      </tr>
    </thead>
  );
};

export default InvoiceHeader;
