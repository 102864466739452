import { HTMLAttributes } from "react";

interface CollapseTitleProps extends HTMLAttributes<HTMLDivElement> {}

const CollapseTitle = ({
  className,
  children,
  ...props
}: CollapseTitleProps) => {
  return (
    <div
      className={`collapse-title font-semibold ${className || ""}`}
      {...props}
    >
      {children}
    </div>
  );
};

export default CollapseTitle;
