import Dropdown from "components/Dropdown/dropdown";
import { ClientPlanContext } from "contexts/ClientPlanProvider";
import ClientPlanController from "Controllers/ClientPlanController";
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { formatNumberToMonetaryValue } from "utils/DTOs/MonetaryValue";
import { ClientPlanInfo } from "utils/interfaces";
interface RowData {
  row: ClientPlanInfo;
  updatePlans: (id: string | number) => void;
}

const ClientPlanRows: React.FC<RowData> = ({ row, updatePlans }) => {
  const { id: client_id } = useParams();
  const { openModal } = useContext(ClientPlanContext);

  function formatDate(apiDate: string) {
    const date = new Date(apiDate);
    return date.toLocaleString("pt-BR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  }

  const unlinkPlan = async () => {
    if (client_id) {
      await ClientPlanController.unlinkPlan(row.id, client_id);
      updatePlans(client_id);
    }
  };
  const relinkPlan = async () => {
    if (client_id) {
      await ClientPlanController.relinkPlan(row.id, client_id);
      updatePlans(client_id);
    }
  };

  return (
    <React.Fragment key={row.id}>
      <tr className="hover cursor-pointer">
        <th>{row.id}</th>
        <td className="whitespace-nowrap">{row.name}</td>
        <td>
          {" "}
          {row.cancellation_date ? (
            <span className="rounded-badge border border-error/50 bg-error/5 px-3 py-1 text-xs font-medium text-error">
              Cancelado
            </span>
          ) : (
            <span className="rounded-badge border border-success/50 bg-success/5 px-3 py-1 text-xs font-medium text-success">
              Ativo
            </span>
          )}
        </td>
        <td>{formatDate(row.signature_date)}</td>
        <td>{formatNumberToMonetaryValue(row.value)}</td>
        <td>{formatNumberToMonetaryValue(row.discount)}</td>
        <td>
          {formatNumberToMonetaryValue(
            Number(row.value) - Number(row.discount)
          )}
        </td>
        <td className="py-0">
          <Dropdown title="Ações">
            <ul>
              <li>
                <div
                  onClick={() =>
                    openModal({
                      id: row.id,
                      discount: row.discount,
                      observation: row.observation,
                    })
                  }
                >
                  Descontar
                </div>
              </li>
              <li>
                {row.cancellation_date ? (
                  <div onClick={relinkPlan}>Reativar</div>
                ) : (
                  <div onClick={unlinkPlan}>Deletar</div>
                )}
              </li>
            </ul>
          </Dropdown>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default ClientPlanRows;
