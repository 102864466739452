import DrawerContent from "./DrawerContent";
import DrawerRoot from "./DrawerRoot";
import DrawerSideContent from "./DrawerSideContent";

const Drawer = {
  Content: DrawerContent,
  Root: DrawerRoot,
  SideContent: DrawerSideContent,
};

export default Drawer;
